/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import "../styles/globals.css";

import React, { StrictMode, useEffect } from "react";

import { I18nProvider } from "~i18n/I18nProvider";
import { LayoutProvider } from "~layouts";
import { getMixPanelClient } from "~lib/mixpanel/mixpanel";
import ThemeProvider from "~providers/muiThemeProvider";
import SnackBarProvider from "~providers/snackBarProvider";
import { StoreProvider } from "~store/provider";
import type { NextComponentType } from "~types/next";

import { CssBaseline } from "@mui/material";
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

interface CustomAppProps extends AppProps {
  Component: NextComponentType;
  pageProps: Record<string, unknown>;
}

const MyApp: React.FC<CustomAppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    getMixPanelClient().init();
  }, []);

  return (
    <StrictMode>
      <StoreProvider>
        <QueryClientProvider client={queryClient}>
          <I18nProvider>
            <ThemeProvider>
              <CssBaseline />
              <LayoutProvider layout={Component.layout}>
                <SnackBarProvider />
                <Component {...pageProps} />
              </LayoutProvider>
            </ThemeProvider>
          </I18nProvider>
        </QueryClientProvider>
      </StoreProvider>
    </StrictMode>
  );
};

export default MyApp;
