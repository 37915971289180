// /* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { isNullOrUndefined } from "~utils/boolean-utils";
import { voidFunction } from "~utils/functions";

import type { UserEventsEnum } from "./types";

import type { Dict } from "mixpanel-browser";
import mixpanel from "mixpanel-browser";
const env = process.env["NEXT_PUBLIC_ENVIRONEMENT"] ?? "PRODUCTION";

class MixPanel {
  public init() {
    if (!["TEST", "LOCAL"].includes(env)) {
      mixpanel.init(process.env["NEXT_PUBLIC_MIXPANEL_TOKEN"] ?? "", {
        debug: process.env.NODE_ENV !== "production",
        track_pageview: "url-with-path",
        persistence: "localStorage",
      });
    }
  }

  public trackEvent(eventName: UserEventsEnum, props?: Dict) {
    if (!["TEST", "LOCAL"].includes(env)) {
      try {
        mixpanel.track(eventName, props);
      } catch (error: unknown) {
        this.onError(error);
      }
    }
  }

  private onError(_error: unknown) {
    voidFunction();
  }
}

let mixpanelInstance: MixPanel | null = null;

export const getMixPanelClient = (): MixPanel => {
  if (isNullOrUndefined(mixpanelInstance)) {
    mixpanelInstance = new MixPanel();
  }
  return mixpanelInstance;
};
